import styled from "styled-components"

export const IntencjeSectionWrapper = styled.section`
  h4 {
    margin-bottom: 0.3rem;
  }

  h5 {
    font-style: italic;
  }
  table {
    thead tr th {
      border-color: #a1a1a1;
    }

    margin-top: 0.5rem;

    tbody {
      tr {
        margin-top: 1.1rem;
        display: flex;
        flex-direction: column;

        td:first-of-type {
          font-weight: 500;
        }
      }
    }
  }

  @media only screen and (min-width: 992px) {
    table:last-of-type tbody tr {
      display: table-row;
    }
  }
`

export const IntencjeFormatSectionWrapper = styled.section`
  .hour {
    font-weight: 500;
  }
  .dayOfWeek,
  .outside {
    font-weight: var(--fontWeight-semibold);
    color: var(--color-heading);
    font-size: var(--fontSize-2);
    margin: var(--spacing-8) var(--spacing-0) var(--spacing-1) var(--spacing-0);
    font-family: var(--font-heading);
    letter-spacing: -0.025em;
    line-height: var(--lineHeight-tight);
  }
  .outside {
    line-height: var(--lineHeight-tight);
    border-bottom: 1px solid var(--color-accent);
    border-color: rgb(161, 161, 161);
    padding-bottom: 1rem;
  }
  .day {
    font-style: italic;
    font-weight: var(--fontWeight-normal);
    color: var(--color-heading);
    font-size: var(--fontSize-1);
    margin: var(--spacing-0) var(--spacing-0) var(--spacing-4) var(--spacing-0);
    font-family: var(--font-heading);
    letter-spacing: -0.025em;
    line-height: var(--lineHeight-tight);
    border-bottom: 1px solid var(--color-accent);
    border-color: rgb(161, 161, 161);
    padding-bottom: 1rem;
  }
  .description {
    margin: var(--spacing-0) var(--spacing-0) var(--spacing-3) var(--spacing-0);
  }
  strong {
    font-weight: inherit;
  }
  .title {
    font-style: inherit;
    font-weight: var(--fontWeight-semibold);
    color: var(--color-heading);
    font-size: var(--fontSize-3);
    font-family: var(--font-heading);
    letter-spacing: -0.025em;
    line-height: var(--lineHeight-tight);
  }
  table {
    margin-top: var(--spacing-0);
  }
  table strong {
    font-weight: 600;
  }
  tr {
    margin-bottom: 1.1rem;
    display: flex;
    flex-direction: column;

    td:first-of-type {
      font-weight: 500;
    }
  }
  @media only screen and (min-width: 992px) {
    table:last-of-type tbody tr {
      display: table-row;
    }
  }
  em {
    font-style: inherit;
  }
  .outsideDescription {
    margin: var(--spacing-3) var(--spacing-0) var(--spacing-0) var(--spacing-0);
  }
`

const FormatType = {
  TITLE: 1,
  SERVICES: 3,
  OUTSIDE: 4,
}

export function intencjeFormatterNew(html: string): string {
  try {
    const startFragment = "<!--StartFragment-->"
    const indexFragment = html.indexOf(startFragment)
    const baseHtml =
      indexFragment >= 0
        ? html.slice(indexFragment + startFragment.length)
        : html
    let formatType = FormatType.TITLE
    const formatted = baseHtml.split("\n").map(lineRaw => {
      const line = removeRedundantTags(lineRaw).trim()
      if (!line || line.match(/^<[^>]+>$/)) {
        return ""
      }
      switch (formatType) {
        case FormatType.TITLE: {
          const formattedLine = formatTitleOrDay(line)
          if (formattedLine) {
            formatType = FormatType.SERVICES
            return formattedLine
          }
          break
        }
        case FormatType.SERVICES: {
          const outside = line.match(
            /MSZE.*ŚW.?.*POZA.*PARAFI[ĄA].*WSPOMNIE[ĆC].*W.*MODLITWIE.*WIERNYCH/i
          )
          if (outside) {
            formatType = FormatType.OUTSIDE
            return `<div class="outside">${outside[0]}</div><div class="outsideDescription"><table>`
          }
          const formattedLine = formatHour(line)
          if (formattedLine) {
            return formattedLine
          } else {
            return formatDay(line)
          }
        }
        case FormatType.OUTSIDE: {
          var firstMach = line.match(/([A-ZĄĆĘÓŚŁŻŹŃ]{4,}\s[A-ZĄĆĘÓŚŁŻŹŃ ]{3,})\s*(.{10,})/);
          if(firstMach) {
            const name = firstMach[1]
            const description = firstMach[2]
            return `<tr><td>+ ${name}</td><td>${description}</td></tr>`
          }
            return `<tr><td></td><td>${line}</td></tr>`
        }
        default: {
          return ""
        }
      }
      return ""
    })
    return formatted.join("\n") + "\n</table></div>"
  } catch (error) {
    console.error("Error while format page 'intencje'", error)
    return intencjeFormatterOld(html)
  }
}
function intencjeFormatterOld(html: string): string {
  try {
    const startFragment = "<!--StartFragment-->"
    const indexFragment = html.indexOf(startFragment)
    const baseHtml =
      indexFragment >= 0
        ? html.slice(indexFragment + startFragment.length)
        : html
    var replaced = baseHtml.replace(
      /(\d{1,2}\.\d{2} *[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ ]*)[-–]([^<]+)/g,
      '<div class="hour">$1</div><div class="description">$2</div>'
    )
    replaced = replaced.replace(
      /<strong>((PONIEDZIAŁEK)|(WTOREK)|(ŚRODA)|(CZWARTEK)|(PIĄTEK)|(SOBOTA)|(NIEDZIELA))[^\d]*(\d{1,2} [A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]+ \d{4}( ?r.)?)(.*)<\/strong>/gi,
      '<div class="dayOfWeek">$1 $11</div><div class="day">$9</div>'
    )
    replaced = replaced.replaceAll("<br>", "")
    replaced = replaced.replace(
      /<p>(INTENCJE MSZALNE[^<]*)<\/p>/,
      '<div class="title">$1</div>'
    )
    replaced = replaced.replace(
      /<strong>(MSZE ŚW. POZA PARAFIĄ.*wspomnieć w modlitwie wiernych)<\/strong>/,
      '<div class="outside">$1</div>'
    )
    var indexOutside = replaced.indexOf('<div class="outside">')
    replaced =
      replaced.substring(0, indexOutside) +
      replaced
        .substring(indexOutside, replaced.length)
        .replace("<p>", '<div class="outsideDescriptionOld"><table>')
    var indexOutsideDescription = replaced.indexOf(
      '<div class="outsideDescriptionOld">'
    )
    replaced =
      replaced.substring(0, indexOutsideDescription) +
      replaced
        .substring(indexOutsideDescription, replaced.length)
        .replaceAll("<strong>", "<tr><td><strong>")
        .replaceAll("</strong>", "</strong></td><td>")
        .replaceAll("</p>\n", "</td></tr></table></div>")
        .replaceAll("\n", "</td></tr>\n")
        .replaceAll("\n", "</td></tr>\n")
        .replace(/(<td>)[ \s]+/g, "$1")
        .replaceAll("&nbsp;", "")
        .replaceAll("<p>", "")
        .replaceAll("</p>", "")
    return replaced
  } catch (error) {
    console.error("[OLD] Error while format page 'intencje'", error)
    return html
  }
}

function formatTitleOrDay(line: string): string {
  if (line.toUpperCase().includes("INTENCJE MSZALNE")) {
    return `<div class="title">${line}</div>`
  }
  return formatDay(line)
}
function formatDay(line: string): string {
  const dayOfWeekMatch = line.match(/^[\s ]*(\D+)(.*)/)
  if (dayOfWeekMatch) {
    const dayOfWeek = dayOfWeekMatch[1].trim().replace(/[-–\s]+$/, "")
    const day = dayOfWeekMatch[2].trim()
    const dayAndHourMatch = day.match(/(.*)(\d{2}[.:]\d{2})[-–\s]*(.*)/)
    return (
      `<div class="dayOfWeek">${dayOfWeek}</div>
            <div class="day">${dayAndHourMatch?.[1] || day}</div>` +
      (dayAndHourMatch
        ? `<div class="hour">${dayAndHourMatch[2]}</div><div class="description">${dayAndHourMatch[3]}</div>`
        : "")
    )
  }
  return ""
}
function formatHour(line: string): string {
  const hourMatch = line.match(
    /^[\s ]*(\d{1,2}[.:]\d{2} *[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ ]*)[-–\s]*(.*)/
  )
  if (hourMatch) {
    const hour = hourMatch[1].trim()
    const text = hourMatch[2].trim()
    return (
      (hour ? `<div class="hour">${hour}</div>` : "") +
      (text ? `<div class="description">${text}</div>` : "")
    )
  }
  return ""
}

function removeRedundantTags(html: string): string {
  if (!html) {
    return ""
  }
  const tagsToRemove = ["strong", "p", "br", "div", "em"]
  tagsToRemove.forEach(tag => {
    html = html.replace(new RegExp(`<${tag}[^>]*>`, "g"), "")
    html = html.replace(new RegExp(`</${tag}>`, "g"), "")
  })
  return html
}
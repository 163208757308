import * as React from "react"
import { PageProps, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { DataPropsPage } from "../../contracts/data-props-page"
import {
  IntencjeFormatSectionWrapper,
  IntencjeSectionWrapper,
} from "../../styles/intencje"
import { intencjeFormatterNew } from "../../helpers/intencje-formatter"
import { IntencjeMarkdownRemark } from "./intencje"

export default function Rekolekcje(
  props: PageProps<{ markdownRemark: IntencjeMarkdownRemark }>
) {
  const { data, location } = props
  const post = data.markdownRemark
  console.log(post)
  return (
    <Layout title={post.frontmatter.title} location={location}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </header>
        {post.frontmatter.isFormat ? (
          <IntencjeFormatSectionWrapper
            dangerouslySetInnerHTML={{
              __html: intencjeFormatterNew(post.html),
            }}
            itemProp="articleBody"
          />
        ) : (
          <IntencjeSectionWrapper
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        )}
      </article>
    </Layout>
  )
}

export const query = graphql`
  {
    markdownRemark(frontmatter: { layout: { eq: "rekolekcje" } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY", locale: "pl")
        isFormat
      }
    }
  }
`
